export const TicketTypes = {
  ADD_COMPANY_MANAGER: "Bitte fügen Sie mich als Firmenverwalter:in hinzu",
  ADD_EMAIL_DOMAINS: "Bitte fügen Sie folgende E-Mail Domains hinzu",
  CHANGE_EMAIL_ADDRESS: "Bitte ändern Sie meine E-Mail-Adresse",
  DELETE_PROFILE: "Bitte löschen Sie mein Profil",
  CHANGE_COMPANY_DATA: "Bitte ändern Sie meine Firmendaten",
  SWITCH_COMPANY: "Firmenwechsel",
  TERMINATE_MEMBERSHIP: "Kündigung",
  COMPANY_MERGER: "Firmenfusion",
  REGISTER_WITH_COMPANY: "Bitte fügen Sie mich als Mitarbeiter dieser Firma hinzu",
  REQUEST_INFORMATION: "Bitte teilen Sie uns fehlende Profilinformationen mit",
  OTHER: "Sonstige Anfrage",
  ADD_PROJECT_ROOM: "Projektraum Hinzufügen",
  CANCEL_MEMBERSHIP: "Ich möchte meine Firmenmitgliedschaft kündigen"
};

export const NormalTicketTypes = {
  ADD_COMPANY_MANAGER: "Bitte fügen Sie mich als Firmenverwalter:in hinzu",
  ADD_EMAIL_DOMAINS: "Bitte fügen Sie folgende E-Mail Domains hinzu",
  CHANGE_EMAIL_ADDRESS: "Bitte ändern Sie meine E-Mail-Adresse",
  DELETE_PROFILE: "Bitte löschen Sie mein Profil",
  REGISTER_WITH_COMPANY: "Bitte fügen Sie mich als Mitarbeiter dieser Firma hinzu",
  REQUEST_INFORMATION: "Bitte teilen Sie uns fehlende Profilinformationen mit",
  OTHER: "Sonstige Anfrage"
};

export const OtherTicketTypes = {
  CHANGE_COMPANY_DATA: "Bitte ändern Sie meine Firmendaten",
  SWITCH_COMPANY: "Firmenwechsel",
  TERMINATE_MEMBERSHIP: "Kündigung",
  COMPANY_MERGER: "Firmenfusion",
};
