<template>
  <div class="global-breadcrumb-wrapper">
    <div class="global-breadcrumb">
      <router-link
        v-for="item in breadcrumbItems"
        :to="{ name: item.link_name, params: item.link_params }"
        :key="item.id"
        class="breadcrumb-step"
      >
        <fa :icon="item.icon.join(' ')"></fa>
        <span> {{ item.link_text }}</span>
      </router-link>
    </div>
    <div class="global-breadcrumb-side">
      <BreadcrumbSide />
    </div>
  </div>
</template>
<script>
import BreadcrumbSide from "@/components/layout/BreadcrumbSide.vue";

export default {
  components: {
    BreadcrumbSide,
  },
  data() {
    return {
      breadcrumbItems: [],
    };
  },
  mounted() {
    window.setBreadcrumbItems = (items) => {
      this.breadcrumbItems = items.map((x) => ({
        ...x,
        link_params: JSON.parse(x.link_params),
      }));
    };
  },
};
</script>
