import GuestLayout from "@/layout/GuestLayout.vue";

export const guestRoutes = [
  // auth layout
  {
    path: "/external",
    component: GuestLayout,
    children: [
      {
        path: "",
        name: "external",
        component: () => import("@/views/guest/External.vue"),
      },
      {
        path: "/extern/event/:id",
        name: "external.event",
        component: () => import("@/views/event/show/EventShowExternal.vue"),
      },
      {
        path: "/extern/news/:id",
        name: "external.news.show",
        component: () => import("@/views/global-news/show/NewsShow.vue"),
      },
    ],
  },
];
