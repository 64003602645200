import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faXing,
    faFacebook,
    faYoutube,
    faTiktok,
    faXTwitter
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faXing,
    faFacebook,
    faYoutube,
    faTiktok,
    faXTwitter
);
