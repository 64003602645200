export default class EventHandler {
  constructor(handlerFunc) {
    this.handle = handlerFunc;
    if (this.handle instanceof EventHandler) {
      this.handle = this.handle.handle;
    }
  }
  is(other) {
    if (other instanceof Function) {
      return this.handle === other;
    } else {
      return this.handle === other.handle;
    }
  }
}
