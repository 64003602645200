import MainLayout from "@/layout/MainLayout.vue";

export const partnerRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      {
        path: "/partner",
        name: "partner",
        component: () => import("@/views/partner-db/list/PartnerList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/partner/:id",
        name: "partner.profile",
        component: () =>
          import("@/views/partner-db/show/PartnerProfileShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
    ],
  },
];
