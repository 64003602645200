import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
import "./fal";
import "./fas";
import "./far";
import "./fab";
import "./duo";

Vue.component("fa", FontAwesomeIcon);
dom.watch();
