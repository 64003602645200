import MainLayout from "@/layout/MainLayout.vue";

export const miscRoutes = [
  {
    path: "",
    component: () => import("@/layout/MiscLayout.vue"),
    children: [
      {
        path: "/pages/error-404",
        name: "page-error-404",
        component: () => import("@/views/misc/Error404.vue"),
      },
      {
        path: "/transaction",
        name: "transaction",
        component: () =>
          import("@/views/transactional-links/TransactionalLink.vue"),
      },
      {
        path: "/wartung",
        name: "under-construction",
        component: () => import("@/views/misc/UnderConstruction.vue"),
      },
      {
        path: "/nutzungsbedingen-akzeptieren",
        name: "accept.agb",
        component: () => import("@/views/misc/AcceptAGB.vue"),
      },
    ],
  },

  // Redirect to 404 page, if no match found
  {
    path: "*",
    component: MainLayout,
    children: [
      {
        path: "*",
        component: () => import("@/views/misc/Error404.vue"),
      },
    ],
  },
];
