import MainLayout from "@/layout/MainLayout.vue";

export const eventRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      // EVENTS
      {
        path: "/events",
        name: "events",
        component: () => import("@/views/event/list/EventList.vue"),
      },
      {
        path: "/event/new",
        name: "event.new",
        component: () => import("@/views/event/wizard/EventWizard.vue"),
        meta: { permissions: ["event.create"] },
      },
      {
        path: "/event/propose",
        name: "event.propose",
        component: () => import("@/views/event/propose/ProposeEvent.vue"),
      },
      {
        path: "/event/:slug/edit/:tab",
        name: "event.edit",
        component: () => import("@/views/event/wizard/EventWizard.vue"),
        meta: { permissions: ["event.update"] },
      },
      {
        path: "/event/:id",
        name: "event.show" /*  */,
        component: () => import("@/views/event/show/EventShow.vue"),
      },
      {
        path: "/event/:id/stoniert",
        name: "event.cancel.success",
        component: () => import("@/views/event/show/EventCancelSuccess.vue"),
      },
      {
        path: "/event/:id/surveys",
        name: "event.show.surveys",
        component: () => import("@/views/event/show/EventSurveys.vue"),
      },
      {
        path: "/event/:id/surveys/create",
        name: "event.show.surveys.new",
        component: () => import("@/views/event/show/EventCreateSurvey.vue"),
      },
      {
        path: "/event/:id/teilnehmer",
        name: "event.show.member",
        component: () => import("@/views/event/show/EventMember.vue"),
      },
      {
        path: "/event/:id/anmeldungen",
        name: "event.show.applications",
        component: () => import("@/views/event/show/EventApplications.vue"),
        meta: { permissions: ["event.showApplications"] },
      },
      {
        path: "/event/:id/news",
        name: "event.news.list",
        component: () => import("@/views/event/news/EventNewsList.vue"),
      },
      {
        path: "/event/:id/news/:subId",
        name: "event.news.show",
        component: () => import("@/views/event/news/EventNewsShow.vue"),
      },
      {
        path: "/event/:slug/signup",
        name: "event.signup",
        component: () => import("@/views/event/signup/EventSignup.vue"),
      },
      {
        path: "/event-series/:id/signup",
        name: "event-series.signup",
        component: () => import("@/views/event/signup/EventSeriesSignup.vue"),
      },
      {
        path: "/event/:slug/application-successfull",
        name: "event.application-succefull",
        component: () => import("@/views/event/Successfull.vue"),
      },
    ],
  },
];
