<template>
  <div
    class="notification"
    @click="tryToMarkNotificationAsRead"
    :class="notification.withDate && !notification.read_at ? 'unread' : ''"
  >
    <div class="notification-icon">
      <div class="row">
        <div class="col-6">
          <span><i :class="'fa-regular ' + icon"></i></span>
        </div>
        <div class="col-6 text-right">
          <a @click="tryToDeleteNotification" href="#" title="Benachrichtigung löschen" class="hover-orange delete-notification-button"><i class="fa-light fa-trash"></i></a>
        </div>
      </div>
    </div>
    <div class="notification-text">
      <slot :hasBeenNotified="setNotificationRead"></slot>
      <div class="notification-text-time">{{ relativeTime }}</div>
    </div>
    <!-- <div v-if="notification.withDate && notification.read_at">
      Gelesen: {{ $formatDateTime(notification.read_at) }}
    </div> -->
  </div>
</template>
<script>
import moment from "moment";
import "moment/locale/de";
import gql from "graphql-tag";
moment.locale("de");

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    time: {
      type: [Date, String],
      required: true,
    },
    notification: Object,
    mustBeExplicitlyApproved: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    relativeTime() {
      return moment(this.time).fromNow();
    },
  },
  methods: {
    tryToMarkNotificationAsRead() {
      if (this.mustBeExplicitlyApproved) {
        return;
      }

      this.setNotificationRead()
    },
    setNotificationRead() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation MarkAsRead($userId: ID!, $ids: [ID!]) {
              markAsRead(userId: $userId, ids: $ids){
                id
              }
            }
          `,
          variables: {
            userId: localStorage.getItem("user_id"),
            ids: [this.notification.id]
          },
        })
        .then(({ markAsRead }) =>
          this.$toast(`Benachrichtigungen als gelesen markiert`)
        );
    },
    tryToDeleteNotification() {
      if (this.mustBeExplicitlyApproved) {
        return;
      }

      this.deleteNotification();
    },
    deleteNotification() {
      this.$emit('deleteNotification', this.notification.id);
    },
  },
};
</script>
