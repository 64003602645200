import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faUserTie,
  faBrain,
  faFileAlt,
  faClock,
  faCar,
  faCalendarAlt,
  faPhoneAlt,
  faEnvelope,
  faHandshakeAlt,
  faRepeat,
  faEuroSign,
  faSpinnerThird,
  faPaperclip,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faHome,
  faUserTie,
  faBrain,
  faFileAlt,
  faClock,
  faCar,
  faCalendarAlt,
  faPhoneAlt,
  faEnvelope,
  faHandshakeAlt,
  faRepeat,
  faEuroSign,
  faSpinnerThird,
  faPaperclip
);
