import gql from "graphql-tag";

let contextPermissions = "{}";
let directPermissions = "{}";

export default class PermissionStorage {
  constructor(preloadPermissions) {
    this.preloadPermissions = preloadPermissions;
    this.initialized = false;
  }

  async init(apollo) {
    this.apollo = apollo;
    this.listenForPermissionFlush();
    await this.resolveUser();
    if (this.user) {
      window.localStorage.setItem("user_id", this.user);
    } else {
      window.localStorage.removeItem("user_id");
    }
    await this.ensurePermissionsLoaded(this.preloadPermissions);
    delete this._localStorage; // no need to keep this large object around
    this.initialized = true;
  }

  listenForPermissionFlush() {
    setInterval(async () => {
      const { data } = await this.apollo.query({
        query: gql`
          query {
            me {
              id
              permissions_flushed
            }
          }
        `,
      });
      if (!data.me) {
        return;
      }
      if (
        localStorage.getItem("permissions_flushed") !==
        data.me.permissions_flushed
      ) {
        localStorage.setItem(
          "permissions_flushed",
          data.me.permissions_flushed
        );
        this.invalidateCache();
      }
    }, 5000);
  }

  async resolveUser() {
    try {
      this.user = await this.apollo
        .query({
          query: gql`
            query Me {
              me {
                id
              }
            }
          `,
        })
        .then((data) => data.data.me.id);
    } catch (e) {
      //TODO
    }
  }

  async ensurePermissionsLoaded(permissions) {
    let missingDirect = [];
    let missingContextPermissions = new Map();
    const directPermissions = this.getDirectPermissions();
    for (const perm of permissions) {
      if (typeof perm === "string") {
        if (!(perm in directPermissions)) {
          missingDirect.push(perm);
        }
      } else {
        if (perm.context === null || perm.context === undefined) {
          if (!(perm.name in directPermissions)) {
            missingDirect.push(perm.name);
          }
        } else {
          const contextKey = this.buildContextKey(perm.context);
          const loaded = this.getContextPermissions(contextKey);
          if (!(perm.name in loaded)) {
            let toLoad = missingContextPermissions.get(contextKey) || [];
            toLoad.push(perm);
            missingContextPermissions.set(contextKey, toLoad);
          }
        }
      }
    }
    const toLoad = [
      ...missingDirect,
      ...[...missingContextPermissions.values()].flat(),
    ];
    if (toLoad.length > 0) {
      await this.loadPermissions(toLoad);
      this.flushPermissions();
    }
  }

  flushPermissions() {
    directPermissions = JSON.stringify(this.directPermissions || {})
    contextPermissions = JSON.stringify(
      Object.fromEntries(
        this.contextPermissions ? this.contextPermissions.entries() : []
      )
    )
  }

  async invalidateCache() {
    directPermissions = "{}";
    contextPermissions = "{}";
    this.initialized = false;
    await this.resolveUser();
    this.contextPermissions = new Map();
    this.directPermissions = {};
    await this.ensurePermissionsLoaded(this.preloadPermissions);
    this.initialized = true;
  }

  async loadPermissions(permissions) {
    const keys = permissions.map((p) => this.buildBackendKey(p));
    permissions = await this.apollo
      .query({
        query: gql`
          query CheckPermissions($keys: [String!]!) {
            checkPermissions(keys: $keys) {
              allowed
              context {
                id
                model
              }
              name
            }
          }
        `,
        variables: {
          keys,
        },
      })
      .then(({ data }) => data.checkPermissions);
    permissions.forEach((perm) => {
      this.setPermission(perm.name, perm.allowed, perm.context);
    });
  }

  setPermission(name, allowed, context = null) {
    if (context) {
      const key = this.buildContextKey(context);
      let permissions = this.contextPermissions.get(key);
      permissions[name] = allowed;
    } else {
      this.directPermissions[name] = allowed;
    }
  }

  getDirectPermissions() {
    if (!this.initialized) {
      this.directPermissions = JSON.parse(
        directPermissions || "{}"
      );
    }
    return this.directPermissions;
  }

  getContextPermissions(key) {
    if (!this.contextPermissions) {
      this.contextPermissions = new Map();
    }
    let updated = false;
    let loaded = this.contextPermissions.get(key);
    if (!loaded) {
      updated = true;
      if (!this._localStorage) {
        this._localStorage = JSON.parse(
          contextPermissions || "{}"
        );
      }
      loaded = this._localStorage[key];
    }
    if (!loaded) {
      loaded = {};
    }
    if (updated) {
      this.contextPermissions.set(key, loaded);
    }
    return loaded;
  }

  buildContextKey(context) {
    return `perm:m${context.model}:id${context.id}`;
  }

  buildBackendKey(perm) {
    if (typeof perm == "string") {
      return perm;
    } else if (!perm.context) {
      return perm.name;
    } else {
      return `${perm.name}:${perm.context.model}:${perm.context.id}`;
    }
  }

  async checkPermissions(permissions) {
    await this.ensurePermissionsLoaded(permissions);
    let success = true;
    for (const perm of permissions) {
      success &&= this.checkPermissionFast(perm);
    }
    return success;
  }

  checkPermissionFast(permission) {
    let result = this.directPermissions[permission.name] || false;
    if (result) return true;
    if (permission.context) {
      const key = this.buildContextKey(permission.context);
      result = this.getContextPermissions(key)[permission.name] || false;
    }
    return Boolean(result);
  }
}
