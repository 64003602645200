import MainLayout from "@/layout/MainLayout.vue";

export const travelExpenseRoutes = [
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      {
        path: "/travel-expense",
        name: "travel-expense",
        component: () => import("@/views/travel-expense/list/TravelExpenseList.vue"),
      },
      {
        path: "/travel-expense/create",
        name: "travel-expense.create",
        component: () => import("@/views/travel-expense/create/TravelExpenseCreate.vue"),
      },
      {
        path: "/travel-expense/:id/edit",
        name: "travel-expense.edit",
        component: () => import("@/views/travel-expense/edit/TravelExpenseEdit.vue"),
      },
    ],
  },
];
