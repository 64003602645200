import EventHandler from "./EventHandler";

export default class NamedEventHandler extends EventHandler {
  constructor(handleFunc, name) {
    super(handleFunc);
    this.name = name;
    this.handle = handleFunc;
    if (this.handle instanceof EventHandler) {
      this.handle = this.handle.handle;
    }
  }
  is(other) {
    if (typeof other === "string") {
      return this.name === other;
    }
    if (!(other instanceof NamedEventHandler)) {
      return false;
    }
    return other.name === this.name;
  }
}
