const typenameMapping = {
    Gremium: {
        icon: "fa-light fa-screen-users",
        title: (item) => item.shortened_type + item.name,
        url: (item) => `/gremium/${item.route_slug}`,
        date: (item) => item.created_at,
    },
    News: {
        icon: "fa-light fa-comment",
        title: (item) => item.title,
        url: (item) => {
            const gremium = item.gremium.length > 0 ? item.gremium[0] : null;
            const gremium_slug = gremium?.route_slug;
            const prefix =
                typeof gremium_slug === "string"
                    ? `/gremium/${gremium_slug}/news`
                    : `/news`;

            return `${prefix}/${item.route_slug}`;
        },
        date: (item) => item.created_at,
    },
    User: {
        icon: "fa-light fa-user",
        title: (item) => {
            let suffix = item.company ? ` (${item.company.name1})` : "";

            return `${item.first_name} ${item.last_name}${suffix}`;
        },
        url: (item) => `/benutzer/${item.id}`,
        date: () => "",
        items: (item) => [
            { icon: "fa-light fa-buildings", title: "Firma", value: item.company ? `${item.company.name1} ${item.company.name2 || ""}`.trim() : "(Gast)" },
        ],
        shortTitle: (item) => `${item.first_name} ${item.last_name}`
    },
    TopicComment: {
        icon: "fa-light fa-poll-people",
        title: (item) => `${item.user.first_name} ${item.user.last_name}`,
        content: (item) => item.content.text_content,
        url: (item) =>
            `/gremium/${item.topic.forum.gremium_route_slug}/forum/${item.topic.route_slug}/comment/${item.id}`,
        date: (item) => item.created_at,
    },
    File: {
        icon: "fa-light fa-file",
        title: (item) => item.name,
        url: (item) => `/datei/${item.id}`,
        date: (item) => item.created_at,
    },
    Company: {
        icon: "fa-light fa-buildings",
        title: (item) => `${item.name1} ${item.name2 || ''}`.trim(),
        url: (item) => `/firma/${item.id}`,
        date: (item) => item.created_at,
        items: (item) => [
            { icon: "fa-light fa-brain-circuit", title: "Branche", value: item.field?.name },
            { icon: "fa-light fa-hashtag", title: "Mitgliedsnummer", value: String(item.membership?.number) },
        ],
    },
    DsagEvent: {
        icon: "fa-light fa-calendar",
        title: (item) => item.name,
        url: (item) => `/event/${item.route_slug}`,
        date: (item) => item.start,
    },
    ForumTopic: {
        icon: "fa-light fa-calendar",
        title: (item) => item.title,
        url: (item) =>
            `/gremium/${item.forum.gremium_route_slug}/forum/${item.route_slug}`,
        date: (item) => item.created_at,
    },
    CompanyPartnerProfile: {
        icon: "fa-light fa-buildings",
        title: (item) => typenameMapping.Company.title(item.comp) + " (Partner)",
        url: (item) => `/partner/${item.id}`,
        date: (item) => item.created_at,
        items: (item) => typenameMapping.Company.items(item.comp)
    },
};

export default typenameMapping;