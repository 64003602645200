import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faEnvelope,
  faEllipsisV,
  faTimes,
  faSearch,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faArchive,
  faUser,
  faSync,
  faPen,
  faLock,
  faSignOut,
  faPlus,
  faMinus,
  faCheck,
  faSave,
  faCircle,
  faFlagCheckered,
  faPhoneAlt,
  faEye,
  faEdit,
  faDotCircle,
  faStarOfLife,
  faExternalLink,
  faUserPlus,
  faExclamationTriangle,
  faFilePdf,
  faFileWord,
  faInfo,
  faTachometerAlt,
  faHome,
  faArrowUp,
  faArrowDown,
  faClock,
  faEllipsisH,
  faHouseChimney,
  faLayerGroup,
  faScreenUsers,
  faPeopleGroup,
  faPhotoFilm,
  faUsers,
  faShareSquare,
  faArrowCircleRight,
  faMessages,
  faChartSimple,
  faDownload,
  faSquareUser,
  faHeart,
  faBell,
  faPaperPlane,
  faStar,
  faUserMinus,
  faCircleExclamation,
  faUserClock,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faUpload,
  faFaceSmile,
  faThumbsUp,
  faThumbsDown,
  faRight,
  faFlagPennant,
  faPhone,
  faLocationDot,
  faBuilding,
  faCalendar,
  faUserPolice,
  faQuestion,
  faBuildings,
  faFileCsv,
  faCalendarDay,
  faSquarePollVertical,
  faUserPoliceTie,
  faBug,
  faGear,
  faNewspaper,
  faMessagePlus,
  faPollPeople,
  faFileImage,
  faScrewdriverWrench,
  faBan,
  faHandshake,
  faFileInvoice
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faClock,
  faArrowLeft,
  faEnvelope,
  faEllipsisV,
  faTimes,
  faSearch,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faArchive,
  faUser,
  faSync,
  faPen,
  faLock,
  faSignOut,
  faPlus,
  faMinus,
  faCheck,
  faArrowRight,
  faSave,
  faCircle,
  faFlagCheckered,
  faPhoneAlt,
  faEye,
  faEdit,
  faDotCircle,
  faStarOfLife,
  faExternalLink,
  faUserPlus,
  faExclamationTriangle,
  faFilePdf,
  faFileWord,
  faInfo,
  faTachometerAlt,
  faHome,
  faArrowUp,
  faArrowDown,
  faEllipsisH,
  faHouseChimney,
  faLayerGroup,
  faScreenUsers,
  faPeopleGroup,
  faPhotoFilm,
  faUsers,
  faShareSquare,
  faArrowCircleRight,
  faMessages,
  faChartSimple,
  faDownload,
  faSquareUser,
  faHeart,
  faStar,
  faBell,
  faPaperPlane,
  faUserMinus,
  faCircleExclamation,
  faUserClock,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faUpload,
  faFaceSmile,
  faThumbsUp,
  faThumbsDown,
  faRight,
  faFlagPennant,
  faPhone,
  faLocationDot,
  faBuilding,
  faCalendar,
  faUserPolice,
  faQuestion,
  faBuildings,
  faFileCsv,
  faCalendarDay,
  faSquarePollVertical,
  faUserPoliceTie,
  faBug,
  faGear,
  faNewspaper,
  faMessagePlus,
  faPollPeople,
  faFileImage,
  faScrewdriverWrench,
  faBan,
  faHandshake,
  faFileInvoice
);
