<template>
  <div>
    <template v-if="permissions['admin.show']">
      <span>Version: {{ version }}</span>
      <a
        href="https://dsagnet.de/content/gitlab-infoseite"
        class="mr-2 hover-orange"
        ><i class="fal fa-bug"></i> Bug Melden</a
      >
    </template>
    <!-- <template v-else> -->
    <router-link
      :to="{ name: 'support' }"
      class="mr-2 hover-orange"
      style="padding: 8px 0; display: inline-block"
      ><i class="fa-light fa-circle-question mr-1"></i>Hilfe</router-link
    >
    <!-- </template> -->
  </div>
</template>
<script>
setInterval(() => {
  if (console.logs) {
    while (console.logs.length > 100) {
      console.logs.shift();
    }
  }
}, 1000);
export default {
  data() {
    return {
      bugdata: null,
    };
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {},
  loadPermissions() {
    return ["admin.show"];
  },
};
</script>
