import StringHelper from "@/helpers/stringHelper";

export default {
  methods: {
    $generateLink(code, withRandom = true) {
      return process.env.VUE_APP_API_URL + "/api/re/" + code
        + (withRandom ? "?" + StringHelper.generateRandomAlphanumeric(1) : "");
    }
  }
};
