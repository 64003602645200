import MainLayout from "@/layout/MainLayout.vue";

export const projectRoomRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      // Projekträume
      {
        path: "/projektraum/:id",
        name: "projektraum",
        component: () => import("@/views/gremium/GremiumDashboard.vue"),
      },
      {
        path: "/projektraum/:id/verwaltung",
        name: "projektraum.verwaltung",
        component: () => import("@/views/gremium/admin/GremiumAdminDashboard.vue"),
        children: [
          {
            path: ":page",
            name: "projektraum.verwaltung.sub",
            component: () =>
              import("@/views/gremium/admin/GremiumAdminDashboard.vue"),
            meta: { permissions: ["normalUser"] },
          },
        ],
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/member",
        name: "projektraum.member",
        component: () => import("@/views/gremium/list/MemberList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/umfragen",
        name: "projektraum.surveys",
        component: () => import("@/views/gremium/list/SurveyList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/umfragen/erstellen",
        name: "projektraum.surveys.new",
        component: () => import("@/views/gremium/create/CreateSurvey.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/news",
        name: "projektraum.news",
        component: () => import("@/views/news/list/NewsList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/news/:subId",
        name: "projektraum.news.show",
        component: () => import("@/views/news/show/NewsShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/events",
        name: "projektraum.events",
        component: () => import("@/views/gremium/event/list/EventList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/forum",
        name: "projektraum.forum",
        component: () => import("@/views/topic/list/TopicList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/forum/:subid",
        name: "projektraum.topic.show",
        component: () => import("@/views/topic/show/TopicShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/forum/:subid/comment/:commentId",
        name: "projektraum.topic.show-comment",
        component: () => import("@/views/topic/show/TopicShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/dateien",
        name: "projektraum.files",
        component: () => import("@/views/gremium/show/ProjectRoomFiles.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/projektraum/:id/aufgabenliste",
        name: "projektraum.canban",
        component: () => import("@/views/gremium/canban/Canban.vue"),
      },
    ],
  },
];
