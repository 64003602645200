import MainLayout from "@/layout/MainLayout.vue";

export const userRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: {name: 'home'},
    children: [
      {
        path: "/benutzer/:id",
        name: "user.show",
        component: () => import("@/views/user/show/UserShow.vue"),
      },
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/views/chat/list/ChatList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/chat/:id",
        name: "chat.show",
        component: () => import("@/views/chat/list/ChatList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/mein-profil",
        name: "me",
        component: () => import("@/views/me/show/MeShow.vue"),
      },
      {
        path: "/mein-profil/privatsphaere",
        name: "me.privacy",
        component: () => import("@/views/me/show/MePrivacy.vue"),
      },
      {
        path: "/mein-profil/benachrichtigungen",
        name: "me.notifications",
        component: () => import("@/views/me/show/MeNotifications.vue"),
      },
      {
        path: "/mein-profil/abonnements",
        name: "me.abonnements",
        component: () => import("@/views/me/show/MeAbbonnements.vue"),
      },
      {
        path: "/mein-profil/favoriten",
        name: "me.favorites",
        component: () => import("@/views/me/show/MeFavorites.vue"),
      },
      {
        path: "/mein-profil/antraege",
        name: "me.requests",
        component: () => import("@/views/me/requests/MeRequests.vue"),
      },
      {
        path: "/mein-profil/antraege/firmenwechsel",
        name: "me.change-company",
        component: () => import("@/views/me/requests/SwitchCompany.vue"),
      },
      {
        path: "/mein-profil/antraege/firma-hinzufuegen",
        name: "me.register-with-company",
        component: () => import("@/views/me/requests/RegisterWithCompany.vue"),
      },
      {
        path: "/mein-profil/antrag/:id",
        name: "me.request",
        component: () => import("@/views/me/requests/RequestShow.vue"),
      },
    ],
  },
];
