<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
    @deleteNotification="deleteNotification"
  >
    <template
      v-if="
        notification.replyTo &&
        notification.user === notification.replyTo.user_id
      "
    >
      <router-link
        class="hover-orange"
        :to="{
          name: 'topic.show-comment',
          params: {
            id: notification.gremiumSlug,
            subid: notification.topicSlug,
            commentId: notification.commentId,
          },
        }"
        >Neue Antwort</router-link
      >
      auf
      <router-link
        class="hover-orange"
        :to="{
          name: 'topic.show-comment',
          params: {
            id: notification.gremiumSlug,
            subid: notification.topicSlug,
            commentId: notification.replyTo.id,
          },
        }"
        >Ihren Kommentar</router-link
      >
      im {{ notification.gremiumKind }}
      <router-link
        class="hover-orange"
        :to="{
          name: 'gremium',
          params: {
            id: notification.gremiumSlug,
          },
        }"
        >{{ notification.gremiumTitle }}</router-link
      >
      zum Thema
      <router-link
        class="hover-orange"
        :to="{
          name: 'topic.show',
          params: {
            id: notification.gremiumSlug,
            subid: notification.topicSlug,
          },
        }"
        >{{ notification.topicTitle }}</router-link
      >
    </template>
    <template v-else>
      <router-link
        class="hover-orange"
        :to="{
          name: 'topic.show-comment',
          params: {
            id: notification.gremiumSlug,
            subid: notification.topicSlug,
            commentId: notification.commentId,
          },
        }"
        >Neuer Kommentar</router-link
      >
      zum Thema
      <router-link
        class="hover-orange"
        :to="{
          name: 'topic.show',
          params: {
            id: notification.gremiumSlug,
            subid: notification.topicSlug,
          },
        }"
        >{{ notification.topicTitle }}</router-link
      >
      im {{ notification.gremiumKind }}
      <router-link
        class="hover-orange"
        :to="{
          name: 'gremium',
          params: {
            id: notification.gremiumSlug,
          },
        }"
        >{{ notification.gremiumTitle }}</router-link
      >
    </template>
  </notification-layout>
</template>
<script>
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteNotification(id) {
      this.$emit('deleteNotification', id);
    },
  },
};
</script>
