import EventHandler from "./EventHandler";
import EventManager from "./EventManager";
import NamedEventHandler from "./NamedEventHandler";

export default {
  install(Vue) {
    let manager = EventManager.get();
    Vue.prototype.$listenGlobal = (evt, func, name = null) => {
      if (name !== null) {
        manager.subscribe(new NamedEventHandler(func, name));
      }
      manager.subscribe(evt, func);
    };
    Vue.prototype.$unsubscribeGlobal = (evt, func) => {
      manager.unsubscribe(evt, func);
    };
    Vue.mixin({
      created() {
        let globalEventsOption = this.$options.globalEvents;
        let preventUnsubscribe = {};
        if (!globalEventsOption) {
          return;
        }
        for (const [k, v] of Object.entries(globalEventsOption)) {
          if (v instanceof Function) {
            globalEventsOption[k] = new EventHandler(v);
          } else if ("name" in v) {
            globalEventsOption[k] = new NamedEventHandler(v.handle, v.name);
            if (v.preventUnsubscribe) {
              preventUnsubscribe[k] = true;
            }
          } else {
            globalEventsOption[k] = new EventHandler(v.handle);
            if (v.preventUnsubscribe) {
              preventUnsubscribe[k] = true;
            }
          }
          this.$listenGlobal(k, globalEventsOption[k]);
          this.$options.preventUnsubscribe = preventUnsubscribe;
        }
      },
      beforeUnmount() {
        let globalEventsOption = this.$options.globalEvents;
        if (!globalEventsOption) {
          return;
        }
        let preventUnsubscribe = this.$options.preventUnsubscribe;
        for (const [k, v] of Object.entries(globalEventsOption)) {
          if (preventUnsubscribe[v]) {
            return;
          }
          this.$unsubscribeGlobal(k, v);
        }
      },
    });
  },
};
