import EventHandler from "./EventHandler";
import NamedEventHandler from "./NamedEventHandler";

export default class EventManager {
  constructor() {
    this.unnamedListeners = new Map();
    this.namedEventListeners = new Map();
    // let interval = setInterval(async () => {
    //   const userId = window.localStorage.getItem("user_id");
    //   if (userId) {
    //     console.log("here");
    //     clearInterval(interval);
    //     const io = require("socket.io-client");
    //     const socket = io("ws://localhost:3001", {
    //       transports: ["websocket"],
    //       protocols: [4],
    //     });
    //     console.log(socket);
    //     //socket.onAny(console.log);
    //     socket.on("connect", () => {
    //       socket.emit("authenticate", {
    //         user: userId,
    //         token: window.localStorage.getItem("auth-token"),
    //       });
    //     });
    //     socket.on("error", console.log);
    //     socket.on("chatMessage.sent", (...args) => {
    //       console.log(args);
    //     });
    //     this.socket = socket;
    //   }
    // }, 100);
    // console.log(this.socket);
  }
  static get() {
    if (
      window.__dsagGlobalEvents === null ||
      window.__dsagGlobalEvents === undefined
    ) {
      window.__dsagGlobalEvents = new EventManager();
    }
    return window.__dsagGlobalEvents;
  }
  subscribe(eventName, eventHandler) {
    if (eventHandler instanceof Function) {
      this._listenUnnamed(eventName, new EventHandler(eventHandler));
    } else if (eventHandler instanceof NamedEventHandler) {
      this._listenNamed(eventName, eventHandler);
    } else {
      this._listenNamed(eventName, eventHandler);
    }
  }

  unsubscribe(eventName, id) {
    let events = this.unnamedListeners.get(eventName);
    let index = events.findIndex((x) => x.is(id));
    if (index !== -1) {
      events.splice(index, 1);
      return;
    }
    events = this.namedEventListeners.get(eventName);
    let key = [...events.entries()].findIndex((_, v) => v.is(id));
    if (key) {
      events.delete(key);
    }
  }

  _listenUnnamed(eventName, handler) {
    let listeners = this.unnamedListeners.get(eventName);
    if (!listeners) {
      listeners = [];
      this.unnamedListeners.set(eventName, listeners);
    }
    listeners.push(handler);
  }

  _listenNamed(eventName, handler) {
    let listeners = this.namedEventListeners.get(eventName);
    if (!listeners) {
      listeners = new Map();
      this.namedEventListeners.set(eventName, listeners);
    }
    listeners.set(handler.name, handler);
  }

  trigger(eventName, data) {
    const run = this.collectListeners(eventName);
    let exceptions = [];
    return (async () => {
      await Promise.all(
        run.map(async (l) => {
          try {
            await l.handle(data);
          } catch (e) {
            exceptions.push(e);
          }
        })
      );
      if (exceptions.length > 0) {
        throw new Error(
          `${exceptions.length} errors occured while handling event ${eventName}`,
          {
            cause: exceptions,
          }
        );
      }
    })();
  }
  collectListeners(eventName) {
    const unnamed = this.unnamedListeners.get(eventName) || [];
    let named = this.namedEventListeners.get(eventName);
    if (named) {
      named = named.values();
    } else {
      named = [];
    }
    return [...unnamed, ...named];
  }
}
