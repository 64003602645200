import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faBell,
  faBellOn,
  faUserAstronaut,
  faFileAlt,
  faTools,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faMapMarkedAlt,
  faCalendarAlt,
  faPlus,
  faPlusCircle,
  faMapMarkerAlt,
  faArrowRight,
  faArrowLeft,
  faDoorOpen,
  faUser,
  faUsers,
  faUserPlus,
  faCogs,
  faSquare,
  faCheckSquare,
  faEdit,
  faSearch,
  faCheck,
  faList,
  faGripHorizontal,
  faHome,
  faSignOut,
  faCarBuilding,
  faTimes,
  faMinus,
  faClipboardList,
  faClipboardCheck,
  faTachometerSlow,
  faBusinessTime,
  faTags,
  faHeart,
  faPhonePlus,
  faUsersMedical,
  faExclamationCircle,
  faTasks,
  faCheckCircle,
  faTimesCircle,
  faFilter,
  faBug,
  faPen,
  faBriefcase,
  faUserHardHat,
  faFlagAlt,
  faComment,
  faBook,
  faFileCertificate,
  faClock,
  faBars,
  faDownload,
  faStar,
  faPhoneAlt,
  faMobile,
  faFileUser,
  faSave,
  faChartBar,
  faRecycle,
  faLocation,
  faComments,
  faPhotoFilm,
  faClone,
  faBellConcierge,
  faListUl,
  faCalendar,
  faScreencast,
  faLaptopFile,
  faCog,
  faFile,
  faMagnifyingGlass,
  faGear,
  faChartNetwork,
  faBells,
  faCommentPlus,
  faBellSlash,
  faCalendarClock,
  faChartSimple,
  faWavePulse,
  faPollPeople,
  faCommentCaptions,
  faNetworkWired,
  faCalendarLinesPen,
  faTriangleExclamation,
  faFileArrowDown,
  faLink,
  faLock,
  faLinkSimple,
  faFileImage,
  faArrowUpRightFromSquare,
  faBuildings,
  faBarsProgress,
  faHeartCrack,
  faEye,
  faTrash,
  faScreenUsers,
  faPhoneVolume,
  faCalendarDay,
  faCircleInfo,
  faMailbox,
  faEnvelopeOpenText,
  faNewspaper,
  faFileCsv,
  faRightToBracket,
  faTowerControl,
  faCircleQuestion,
  faUserPoliceTie,
  faUpload,
  faBan,
  faFilePen,
  faListCheck,
  faPaperclip,
  faFileExport,
  faPhone,
  faInfinity,
  faSquarePollVertical,
  faMessagePen,
  faThumbsUp,
  faThumbsDown,
  faQuestion,
  faHeadset,
  faArrowRightArrowLeft,
  faLockOpen,
  faFileContract,
  faSquarePlus,
  faImage,
  faSparkles,
  faCloudArrowUp,
  faScrewdriverWrench,
  faPowerOff,
  faUserMinus,
  faIndustry,
  faPeopleGroup,
  faHandshake,
  faToggleOn,
  faToggleOff,
  faCalendarXmark,
  faLaptop,
  faEuro,
  faGraduationCap,
  faPercent,
  faClipboardQuestion,
  faFileInvoice,
  faFolderOpen,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowsCross,
  faCalendarExclamation,
  faBoxArchive,
  faHashtag,
  faBrainCircuit
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faClipboardCheck,
  faLocation,
  faChevronLeft,
  faRecycle,
  faChartBar,
  faSave,
  faFileUser,
  faDownload,
  faClock,
  faBriefcase,
  faPhonePlus,
  faTasks,
  faUsersMedical,
  faExclamationCircle,
  faTags,
  faBuilding,
  faChevronUp,
  faChevronDown,
  faBell,
  faBellOn,
  faUserAstronaut,
  faUserHardHat,
  faFileAlt,
  faTools,
  faEnvelope,
  faChevronRight,
  faMapMarkedAlt,
  faCalendarAlt,
  faPlus,
  faPlusCircle,
  faMapMarkerAlt,
  faArrowRight,
  faDoorOpen,
  faArrowLeft,
  faUser,
  faUsers,
  faUserPlus,
  faCogs,
  faSquare,
  faCheckSquare,
  faEdit,
  faSearch,
  faCheck,
  faGripHorizontal,
  faHome,
  faSignOut,
  faCarBuilding,
  faTimes,
  faClipboardList,
  faTachometerSlow,
  faBusinessTime,
  faHeart,
  faCheckCircle,
  faTimesCircle,
  faFilter,
  faBug,
  faPen,
  faFlagAlt,
  faComment,
  faBook,
  faFileCertificate,
  faMinus,
  faBars,
  faFileCertificate,
  faStar,
  faPhoneAlt,
  faMobile,
  faComments,
  faPhotoFilm,
  faClone,
  faBellConcierge,
  faListUl,
  faCalendar,
  faScreencast,
  faLaptopFile,
  faMagnifyingGlass,
  faList,
  faGear,
  faChartNetwork,
  faPlusCircle,
  faCog,
  faFile,
  faBells,
  faCommentPlus,
  faBellSlash,
  faCalendarClock,
  faChartSimple,
  faWavePulse,
  faPollPeople,
  faCommentCaptions,
  faNetworkWired,
  faCalendarLinesPen,
  faTriangleExclamation,
  faFileArrowDown,
  faLink,
  faLock,
  faLinkSimple,
  faFileImage,
  faArrowUpRightFromSquare,
  faBuildings,
  faBarsProgress,
  faHeartCrack,
  faEye,
  faTrash,
  faScreenUsers,
  faPhoneVolume,
  faCalendarDay,
  faCircleInfo,
  faMailbox,
  faEnvelopeOpenText,
  faNewspaper,
  faFileCsv,
  faRightToBracket,
  faTowerControl,
  faCircleQuestion,
  faUserPoliceTie,
  faUpload,
  faBan,
  faFilePen,
  faListCheck,
  faPaperclip,
  faFileExport,
  faPhone,
  faInfinity,
  faSquarePollVertical,
  faMessagePen,
  faThumbsUp,
  faThumbsDown,
  faQuestion,
  faHeadset,
  faArrowRightArrowLeft,
  faLockOpen,
  faFileContract,
  faSquarePlus,
  faImage,
  faSparkles,
  faCloudArrowUp,
  faScrewdriverWrench,
  faPowerOff,
  faUserMinus,
  faClipboardQuestion,
  faIndustry,
  faPeopleGroup,
  faHandshake,
  faToggleOn,
  faToggleOff,
  faCalendarXmark,
  faLaptop,
  faEuro,
  faGraduationCap,
  faPercent,
  faFileInvoice,
  faFolderOpen,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowsCross,
  faCalendarExclamation,
  faBoxArchive,
  faHashtag,
  faBrainCircuit
);
