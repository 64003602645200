import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOut,
  faSearch,
  faBars,
  faFileAlt,
  faFileDownload,
  faFileUser,
  faUnlock,
  faRepeat,
  faHistory,
  faUserUnlock,
  faCheckCircle,
  faTimesCircle,
  faTimesOctagon,
  faEnvelope,
  faChevronDown,
  faUser,
  faChevronUp,
  faTrash,
  faAngleRight,
  faBell,
  faBellOn,
  faEye,
  faWrench,
  faArrowRight,
  faClone,
  faUsers,
  faArrowUp,
  faArrowDown,
  faCheck,
  faArrowLeft,
  faFloppyDisk,
  faXmark,
  faScreenUsers,
  faEuroSign,
  faAngleLeft,
  faThumbtack,
  faPlus,
  faGripLines,
  faCalendarDays,
  faUpload,
  faCalendarClock,
  faPaperclip,
  faImage,
  faGlobe,
  faMapMarkedAlt,
  faEllipsis,
  faSquarePollVertical,
  faMessagePen,
  faPen,
  faCalendar,
  faPollPeople,
  faNewspaper,
  faComment,
  faBallot,
  faIndustryWindows,
  faUserPlus,
  faRightToBracket,
  faExclamation,
  faLink,
  faUserMinus,
  faMessageExclamation,
  faFileImage,
  faCircleQuestion,
  faHandshake
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faClone,
  faWrench,
  faSignOut,
  faSearch,
  faBars,
  faFileAlt,
  faFileDownload,
  faFileUser,
  faUnlock,
  faRepeat,
  faHistory,
  faUserUnlock,
  faCheckCircle,
  faTimesCircle,
  faTimesOctagon,
  faEnvelope,
  faChevronDown,
  faUser,
  faChevronUp,
  faTrash,
  faAngleRight,
  faBell,
  faBellOn,
  faEye,
  faArrowRight,
  faUsers,
  faArrowUp,
  faArrowDown,
  faCheck,
  faArrowLeft,
  faFloppyDisk,
  faXmark,
  faScreenUsers,
  faEuroSign,
  faAngleLeft,
  faThumbtack,
  faPlus,
  faGripLines,
  faCalendarDays,
  faUpload,
  faCalendarClock,
  faGlobe,
  faPaperclip,
  faImage,
  faMapMarkedAlt,
  faEllipsis,
  faSquarePollVertical,
  faMessagePen,
  faPen,
  faCalendar,
  faPollPeople,
  faNewspaper,
  faComment,
  faBallot,
  faIndustryWindows,
  faUserPlus,
  faRightToBracket,
  faExclamation,
  faLink,
  faUserMinus,
  faMessageExclamation,
  faFileImage,
  faCircleQuestion,
  faHandshake
);
