<template>
  <img v-if="internalSrc" :src="internalSrc" :alt="alt" :loading="loading" />
</template>
<script>
export default {
  props: {
    alt: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      required: true,
    },
    loading: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internalSrc: null,
      originalSrc: null,
    };
  },
  watch: {
    src(val) {
      this.updateSrc(val);
    },
  },
  mounted() {
    this.updateSrc(this.src);
  },
  methods: {
    updateSrc(val) {
      console.log('updateSrc', val);
      if (typeof val === 'undefined' || val === null) {
        return;
      }

      if (val === this.originalSrc) {
        return;
      }

      this.originalSrc = val;

      if (!val.startsWith(this.$fileUrlBase)) {
        this.internalSrc = val;
        return;
      }

      fetch(val, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth-token"),
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          this.internalSrc = URL.createObjectURL(blob);
        });
    },
  },
};
</script>
