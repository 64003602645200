<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
    @deleteNotification="deleteNotification"
  >
    <template>
      {{ notification.textBeforeLink }}
      <router-link class="hover-orange" :to="notification.linkTo">{{
        notification.linkText
      }}</router-link>
      {{ notification.textAfterLink }}
    </template>
  </notification-layout>
</template>
<script>
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteNotification(id) {
      this.$emit('deleteNotification', id);
    },
  },
};
</script>
