import AuthLayout from "@/layout/AuthLayout.vue";

export const authRoutes = [
  // auth layout
  {
    path: "/login",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/password-reset",
        name: "reset.password",
        component: () => import("@/views/auth/ResetPassword.vue"),
      },
      {
        path: "/registriert",
        name: "user.registered",
        component: () => import("@/views/user/register/Success.vue"),
      },
      {
        path: "/registrieren",
        name: "user.createRegistration",
        component: () => import("@/views/user/register/CreateRegistrationLink.vue")
      },
      {
        path: "/registrieren/:key",
        name: "user.submitRegistrationData",
        component: () => import("@/views/user/register/FillRegistrationData.vue"),
      },
      {
        path: "/antrag-gespeichert",
        name: "user.applicationSaved",
        component: () => import("@/views/user/register/ApplicationSaved.vue"),
      }
    ],
  },
];

export const tempRegAuth = [
  {
    path: "/4xhcz3s2esfaqtge",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "user.register",
        component: () => import("@/views/user/register/RegisterUserNew.vue"),
        meta: { permissions: ["admin.show"] },
      },
    ]
  }
]