<template>
  <router-link class="global-search-result" :to="getUrl(value.item)">
    <div class="icon-container">
      <i :class="getIcon(value.item)"></i>
    </div>

    <div class="info-container">
      {{ getTitle(value.item) }}

      <p v-if="getContent(value.item)">{{ getContent(value.item) }}</p>
    </div>
    <div
      v-if="getDate(value.item)"
      class="info-container text-right"
      style="font-size: 12px; white-space: nowrap"
    >
      <i class="fal fa-calendar-days"></i>
      {{ $formatDateTime(getDate(value.item)) }}
    </div>
    <div
      v-else
      class="info-container text-right"
      style="font-size: 12px; white-space: nowrap"
    ></div>
  </router-link>
</template>

<script>
import typenameMapping from "./typenameMapping";
export default {
  name: "GlobalSearchResult",
  props: ["value"],
  methods: {
    getIcon: (item) => {
      const t = item.__typename;
      return t in typenameMapping
        ? typenameMapping[t].icon + " fa-fw"
        : undefined;
    },
    getTitle: (item) => {
      const t = item.__typename;
      return t in typenameMapping ? typenameMapping[t].title(item) : undefined;
    },
    getUrl: (item) => {
      const t = item.__typename;
      return t in typenameMapping ? typenameMapping[t].url(item) : "";
    },
    getContent: (item) => {
      const t = item.__typename;
      const fn = typenameMapping[t]?.content;
      return fn ? fn(item) : null;
    },
    getDate: (item) => {
      const t = item.__typename;
      const fn = typenameMapping[t]?.date;
      return fn ? fn(item) : null;
    },
  },
};
</script>
