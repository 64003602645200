export default {
  methods: {
    $translateEventType(type) {
      if (type == "ONLINE") {
        return "Online";
      } else if (type == "IN_PERSON") {
        return "Vor Ort";
      } else if (type == "HYBRID") {
        return "Hybrid";
      } else {
        return;
      }
    },
  },
};