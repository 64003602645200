import Vue from "vue";
import { makeNotification } from "./Notification";
export default Vue.extend({
  name: "ShowNotification",
  functional: true,
  props: {
    notification: {
      type: Object,
      required: true,
    },
    withDate: {
      type: Boolean
    }
  },
  render(h, context) {
    const notification = makeNotification(context.props.notification);
    if (!notification) {
      console.log("Failed to render notification", context.props.notification);
    }
    const withDate = context.props.withDate;
    notification.withDate = withDate;
    context.data.attrs.notification = notification;
    return h(notification.getComponent(), context.data, context.children);
  },
});
