import MainLayout from "@/layout/MainLayout.vue";

export const gremiumRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      // GREMIUM
      {
        path: "/gremium/:id",
        name: "gremium",
        component: () => import("@/views/gremium/GremiumDashboard.vue"),
      },
      {
        path: "/gremium/:id/verwaltung",
        name: "gremium.verwaltung",
        component: () =>
          import("@/views/gremium/admin/GremiumAdminDashboard.vue"),
        children: [
          {
            path: ":page",
            name: "gremium.verwaltung.sub",
            component: () =>
              import("@/views/gremium/admin/GremiumAdminDashboard.vue"),
            meta: { permissions: ["normalUser"] },
          },
        ],
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/member",
        name: "gremium.member",
        component: () => import("@/views/gremium/list/MemberList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/umfragen",
        name: "gremium.surveys",
        component: () => import("@/views/gremium/list/SurveyList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/umfragen/erstellen",
        name: "gremium.surveys.new",
        component: () => import("@/views/gremium/create/CreateSurvey.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/news",
        name: "gremium.news",
        component: () => import("@/views/news/list/NewsList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/news/:subId",
        name: "gremium.news.show",
        component: () => import("@/views/news/show/NewsShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/events",
        name: "gremium.events",
        component: () => import("@/views/gremium/event/list/EventList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/forum",
        name: "gremium.forum",
        component: () => import("@/views/topic/list/TopicList.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/forum/:subid",
        name: "topic.show",
        component: () => import("@/views/topic/show/TopicShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/forum/:subid/comment/:commentId",
        name: "topic.show-comment",
        component: () => import("@/views/topic/show/TopicShow.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/dateien",
        name: "gremium.files",
        component: () => import("@/views/gremium/show/GremiumFiles.vue"),
        meta: { permissions: ["normalUser"] },
      },
      {
        path: "/gremium/:id/aufgabenliste",
        name: "gremium.canban",
        component: () => import("@/views/gremium/canban/Canban.vue"),
      },
    ],
  },
];
