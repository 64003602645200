import { onLogout } from "@/vue-apollo";

export default {
  methods: {
    $logout() {
      this.$invalidatePermissionCache();
      onLogout(this.$apollo);
      this.$router.push({ name: "login" });
    },
  },
};
