<template>
  <div>
    <header>
      <HeaderActions />
      <NavBar
        @searchChange="onSearchChange"
        :searchVisible="true"
        @searchSubmit="onSearchSubmit"
      />
      <Breadcrumb />
      <div class="global-search-preview" v-if="isPreviewVisible()">
        <GlobalSearch
          :searchQuery="searchQuery"
          :previewMode="true"
          ref="globalSearchComponent"
        />

        <!-- <div class="backdrop"></div> -->
      </div>
    </header>
    <div class="router-wrapper"><router-view /></div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import NavBar from "@/components/layout/Navbar.vue";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import HeaderActions from "@/components/layout/HeaderActions.vue";
import GlobalSearch from "@/components/globalSearch/GlobalSearch.vue";

export default {
  name: "MainLayout",
  components: {
    NavBar,
    Breadcrumb,
    HeaderActions,
    GlobalSearch,
  },
  data() {
    return {
      searchQuery: "",
      didCloseSearch: false,
      boundListener: undefined,
    };
  },
  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            settings {
              id
              terms_accepted_at
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      update(data) {
        if(!data.me.settings.terms_accepted_at){
          this.$router.push({
            name: 'accept.agb'
          })
        } 
      },
    },
  },
  mounted() {
    this.boundListener = this.onWindowClick.bind(this);
    window.addEventListener("click", this.boundListener);
  },
  unmounted() {
    window.removeEventListener("click", this.boundListener);
  },
  methods: {
    onWindowClick(event) {
      const is_visible = this.isPreviewVisible();

      if (!is_visible) {
        return;
      }

      const elem = this.$refs.globalSearchComponent.$el;
      const in_search = elem.contains(event.target);
      const is_search_result =
        event.target.closest(".global-search-result") != null;
      const is_all_results_btn =
        event.target.closest(".all-results-btn") != null;

      if (!in_search || is_search_result || is_all_results_btn) {
        this.didCloseSearch = true;
      }
    },
    onSearchChange(text) {
      this.searchQuery = text;
      this.didCloseSearch = false;
    },
    onSearchSubmit(text) {
      this.searchQuery = text;
      this.didCloseSearch = true;

      const component = this.$refs.globalSearchComponent;

      if (component) {
        component.navigateToFullPage();
      } else {
        this.$router.push({
          name: "global-search",
          query: {
            query: this.searchQuery,
            page: 1,
            tags: "",
          },
        });
      }
    },
    isPreviewVisible() {
      return !this.didCloseSearch && this.searchQuery.trim().length > 0;
    },
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
