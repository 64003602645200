<template>
  <div class="notifications" id="notifications-popover">
    <div class="notifications-header">
      <div class="notifications-header-text">
        <template v-if="notifications.length > 99">99+</template>
        <template v-else>{{ notifications.length }}</template>
        Benachrichtigungen
      </div>
    </div>
    <div class="notifications-list">
      <simplebar class="ps-h400">
        <show-notification
          v-for="notification in showNotifications"
          :key="notification.id"
          :notification="notification"
        ></show-notification>
        <div v-if="hiddenNotificationcount">
          und {{ hiddenNotificationcount }} weitere
        </div>
      </simplebar>
    </div>
    <div class="notifications-footer" v-if="notifications.length">
      <b-button variant="clean" @click="markAllRead"
        >Alle Benachrichtigungen als gelesen markieren</b-button
      >
    </div>
  </div>
</template>
<script>
import ShowNotification from "@/components/notifications/ShowNotification";
import gql from "graphql-tag";
export default {
  components: {
    ShowNotification,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    markAllRead() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation MarkAllRead($userId: ID!) {
              markAllRead(userId: $userId)
            }
          `,
          variables: {
            userId: localStorage.getItem("user_id"),
          },
        })
        .then(({ markAllRead }) =>
          this.$toast(`Benachrichtigungen als gelesen markiert`)
        );
    },
  },
  computed: {
    showNotifications() {
      return this.notifications;
    },
    hiddenNotificationcount() {
      if (this.notifications.length < this.showNotifications.length) {
        return 0;
      }
      return this.notifications.length - this.showNotifications.length;
    },
  },
};
</script>
