export default {
  methods: {
    shortenType(gremium) {
      return gremium.shortened_type || ""
    },
    $gremiumKind(gremium) {
      if (gremium?.type === "PROJECT_ROOM") {
        return "Projektraum"
      }
      return "Gremium"
    }
  },
};
