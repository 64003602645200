const bannedFileTypes = {
    "application/x-msdownload": true,
    "application/exe": true,
    "application/x-exe": true,
    "application/dos-exe": true,
    "vms/exe application/x-winexe": true,
    "application/msdos-windows": true,
    "application/x-msdos-program": true,
    "vnd.microsoft.portable-executable": true,
    "application/x-elf": true,
    "application/java-archive": true,
    "application/java-vm": true,
    "application/x-httpd-java": true,
    "text/javascript": true,
    "application/javascript": true,
    "application/vnd.apple.installer+xml": true,
    "application/x-sh": true,
    "application/vnd.ms-msi": true,
    "application/x-windows-installer": true,
    "application/x-msi": true,
    "application/x-ms-installer": true
}

export default {
    methods: {
        $validateFiles(files) {
            for (const file of files) {
                if (bannedFileTypes[file.type] || file.type.startsWith("application/x-")) {
                    alert("Dateien des Typs \"" + file.type + "\"können nicht hochgeladen werden");
                    return [];
                }
                return files;
            }
        }
    }
}