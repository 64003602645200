<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :mustBeExplicitlyApproved="true"
    :notification="notification"
    @deleteNotification="deleteNotification"
    v-slot="scope"
  >
    {{ notification.user.first_name }} {{ notification.user.last_name }} hat Sie
    zum Gruppenchat "{{ notification.chat.name }}" eingeladen.
    <b-row
      class="notification-chat-buttons"
      v-if="!notification.action_taken && !actionTaken"
    >
      <b-col>
        <b-button
          variant="success"
          size="sm"
          class="my-2"
          @click="() => acceptChatInvite(scope.hasBeenNotified)"
          >Annehmen</b-button
        >
      </b-col>
      <b-col>
        <b-button variant="danger" size="sm" @click="rejectChatInvite"
          >Ablehnen</b-button
        >
      </b-col>
    </b-row>
    <b-row class="notification-chat-buttons" v-else>
      <b-col> Anfrage bearbeitet </b-col>
    </b-row>
  </notification-layout>
</template>
<script>
import gql from "graphql-tag";
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { actionTaken: false };
  },
  methods: {
    deleteNotification(id) {
      this.$emit("deleteNotification", id);
    },
    async setActionTaken() {
      this.actionTaken = true;
      await this.$apollo.mutate({
        mutation: gql`
          mutation NotificationActionTaken($id: ID!) {
            notificationActionTaken(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: this.notification.id,
        },
      });
    },
    async acceptChatInvite(hasBeenNotified) {
      const invite = await this.getChatInvite();
      await this.$apollo.mutate({
        mutation: gql`
          mutation AcceptChatInvite(
            $acceptChatInviteId: ID!
            $input: ChatMembershipInput!
          ) {
            acceptChatInvite(id: $acceptChatInviteId, input: $input) {
              id
            }
          }
        `,
        variables: {
          acceptChatInviteId: invite.id,
          input: {
            receive_emails: true,
            receive_notifications: true,
          },
        },
      });
      this.$toast("Einladung Akzeptiert");
      hasBeenNotified();
      this.setActionTaken();
    },
    async rejectChatInvite(hasBeenNotified) {
      const invite = await this.getChatInvite();
      await this.$apollo.mutate({
        mutation: gql`
          mutation DenyChatInvite($denyChatInviteId: ID!) {
            denyChatInvite(id: $denyChatInviteId) {
              id
            }
          }
        `,
        variables: {
          denyChatInviteId: invite.id,
        },
      });
      this.$toast("Einladung Abgelehnt");
      hasBeenNotified();
      this.setActionTaken();
    },
    async getChatInvite() {
      const { data } = await this.$apollo.query({
        query: gql`
          query Me {
            me {
              id
              invites {
                id
                chat {
                  id
                  name
                }
                inviter {
                  id
                }
              }
            }
          }
        `,
      });
      return (
        data.me.invites.find(
          (x) =>
            x.chat.id === this.notification.chat.id &&
            x.inviter.id === this.notification.user.id
        ) || data.me.invites[0]
      );
    },
  },
};
</script>
