<template>
  <div class="guest-layout">
    <NavBar />
    <div class="router-wrapper"><router-view /></div>
    
  </div>
</template>

<script>
import NavBar from "@/components/layout/Navbar.vue";

export default {
  name: "MainLayout",
  components: {
    NavBar,
  },
};
</script>