<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
  >
    <template>
      {{ notification.textBeforeText }}
      {{ notification.text }}
      {{ notification.textAfterText }}
    </template>
  </notification-layout>
</template>
<script>
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>
