import MainLayout from "@/layout/MainLayout.vue";

export const legalRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: {name: 'home'},
    children: [
      {
        path: "/agb/firmen",
        name: "agb.company",
        component: () => import("@/views/legal/agb/AgbCompany.vue"),
      },
      {
        path: "/agb/benutzer",
        name: "agb.user",
        component: () => import("@/views/legal/agb/AgbCompany.vue"),
      },
      {
        path: "/datenschutz",
        name: "dataPolicy",
        component: () => import("@/views/legal/DataPolicy.vue"),
      },
    ],
  },
];
