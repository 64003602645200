import MainLayout from "@/layout/MainLayout.vue";

export const globalNewsRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: {name: 'home'},
    children: [
      // NEWS
      {
        path: "/news",
        name: "global-news",
        component: () => import("@/views/global-news/list/NewsList.vue"),
      },
      {
        path: "/news/:id",
        name: "news.show",
        component: () => import("@/views/global-news/show/NewsShow.vue"),
      },
    ],
  },
];
